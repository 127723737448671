<template>
    <div>

         

        <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-details">
            <v-row justify="center" class="mb-8">
                <v-col class="pl-0 pr-0 text-left" cols="12">
                    <h1 class="candidate-details__title">Ganadores Ace Of Spades</h1>
                </v-col>
            </v-row>

            <!-- Filtro por año -->
            <v-row justify="end" class="mb-8">
                <v-col class="pl-0 pr-0 text-left" cols="6">
                    <v-select v-model="selectedYear" :items="years" label="Seleccionar Año" outlined dense class="mb-4"
                        @change="getGanadores" />

                </v-col>
            </v-row>

            <!-- Mostrar los ganadores directamente como tarjetas -->
            <v-row v-if="items.length > 0">
                <!-- Iterar sobre los items, y luego sobre cada ganador dentro de 'item.personas' -->
                <v-col v-for="(item, index) in items" :key="index" cols="12" md="12">
                    <v-row>
                        <v-col v-for="(persona, idx) in item.personas" :key="idx" cols="12" md="3">
                            <v-card :class="'card-all-winners-' + programaClase">
                                <v-card-text>
                                    <div class="container-content-card">
                                        <v-img :src="persona.photo" width="125" height="125" class="mb-2"></v-img>
                                        <div class="content-card text-center">
                                            <p style="font-size: 24px; text-transform: capitalize;">
                                                {{ persona.nombre }} {{ persona.apellido }}
                                            </p>
                                            <p style="font-size: 16px;">{{ persona.cargo }}</p>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- Si no hay ganadores, mostramos un mensaje -->
            <v-row v-else>
                <v-col cols="12">
                    <p>No se encontraron ganadores para el año seleccionado.</p>
                </v-col>
            </v-row>
        </div>

        <v-overlay :value="loadingAll">
            <v-progress-circular :size="120" color="#466BE3" indeterminate>
                {{ $translate("general.loading") }}
            </v-progress-circular>
        </v-overlay>
       
    </div>
    
</template>

<script>
import Api from "@/utils/api";
import Snowfall from "@/components/Snowfall.vue"; // Asegúrate de ajustar la ruta de importación



export default {
    
    data() {
        return {
            origin: window.location.origin,
            token: "",
            infoToken: {
                document: "",
            },
            name: "",

            loadingAll: false,
            dialog: false,
            selectedItem: {},
            modalVisible: false,

            items: [], // Los datos de los ganadores agrupados por periodo
            programaClase: "ace-of-spades", // Clase dinámica para los estilos del programa

            selectedYear: new Date().getFullYear(), // Año seleccionado por defecto
            years: [], // Aquí almacenaremos los años disponibles para el filtro
        };
    },

    computed: {
        firstTenPersons() {
            return (item) => item.personas.slice(0, 10);  // Limitar a las primeras 10 personas
        },
    },

    methods: {
        getToken() {
            var token = localStorage.getItem("token");
            var tokenb64 = localStorage.getItem("tokenB64");
            this.token = tokenb64;
            var div = token.split(".");
            if (div.length >= 2) {
                this.infoToken = JSON.parse(atob(div[1]));
                var nameList = this.infoToken.fullName.split(" ");

                if (nameList.length > 1) {
                    if (nameList[0] != "") {
                        this.name = nameList[0];
                    } else {
                        this.name = nameList[1];
                    }
                } else {
                    this.name = "";
                }
            } else {
                this.infoToken = {
                    document: "",
                };
            }
        },

        getGanadores() {
            this.loadingAll = true;

            const requestBody = JSON.stringify({
                programa_id: 3, // ID del programa (3: Ace Of Spades)
                limit: 0,
                user_id: 0,
                vigencia: this.selectedYear, // Año seleccionado
            });

            Api.Reconocimientos()
                .getGanadoresPrograma(this.token, requestBody)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`Error: ${res.statusText}`);
                    }
                    return res.json();
                })
                .then((response) => {
                    if (response && response.data && response.data.length > 0) {
                        const groupedItems = {};

                        // Agrupar los ganadores por periodo
                        response.data.forEach((item) => {
                            const periodoNombre = item.periodo.nombre;

                            if (!groupedItems[periodoNombre]) {
                                groupedItems[periodoNombre] = {
                                    periodo: periodoNombre,
                                    campaña: item.programa.nombre,
                                    personas: [],
                                    icon: require('@/assets/icon/spade.svg'),
                                };
                            }

                            // Agregar los ganadores al periodo correspondiente
                            groupedItems[periodoNombre].personas.push({
                                nombre: item.user.full_name,
                                cargo: item.cargo,
                                photo: item.user.img,
                            });
                        });

                        // Asignamos los elementos agrupados a 'items'
                        this.items = Object.values(groupedItems);
                    } else {
                        this.items = []; // Si no hay datos, aseguramos que 'items' esté vacío
                    }

                    this.loadingAll = false;
                })
                .catch((error) => {
                    console.error("Error al obtener los ganadores:", error);
                    this.items = []; // En caso de error, también vaciamos los items
                    this.loadingAll = false;
                });
        },


        // Obtener los años disponibles para el filtro
        fetchYears() {
            const currentYear = new Date().getFullYear();
            this.years = [];
            for (let i = 0; i <= 5; i++) {
                this.years.push(currentYear - i);
            }
        },



        openDialog(persona) {
            this.selectedItem = persona;
            this.dialog = true;
        },

        openModal(item) {
            localStorage.setItem('selectedPeriod', item.periodo);
            localStorage.setItem('selectedWinners', JSON.stringify(item.personas));
            localStorage.setItem('selectedProgramName', item.campaña);

            this.loadingAll = true;

            setTimeout(() => {
                this.loadingAll = false;
                this.$router.push({ name: 'allWinners' });
            }, 1000);
        },

        getData() {
            this.loadingAll = true;
            setTimeout(() => this.loadingAll = false, 2000);
        },
    },

    mounted() {
        this.getData();
        this.fetchYears();  // Llamamos a fetchYears para llenar los años disponibles
        this.getGanadores();
    },

    created() {
        this.getToken();
    },
};
</script>





<style scoped>
.timeline-container {
    width: 100%;
}

.custom-timeline-item>>>.v-timeline-item__inner-dot {
    background: #fff !important;
}

.custom-timeline-item>>>.v-timeline-item__inner-dot .v-image .v-image__image {
    background-size: contain !important;
}

.campain-card {
    font-size: 1.5rem;
    font-weight: 500;
    color: #ac141c;
}

.v-dialog__content>>>.custom-popup-reconocimientos.super-stars {
    border-radius: 25px !important;
}

.custom-popup-reconocimientos>>>.v-dialog {
    border-radius: 25px !important;
}

.custom-popup-reconocimientos>>>.v-card {
    border-radius: 25px !important;
}

.custom-popup-reconocimientos .v-card {
    position: relative;
    height: 550px !important;
    border-radius: 25px !important;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: #ac141c !important;
    border: solid #fff;
    border-radius: 25px !important;
}

.custom-popup-reconocimientos .v-card::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.custom-popup-reconocimientos .v-card::after {
    content: '';
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}



.container-content-card {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-flow: column;
}

.container-content-card .v-image {
    border: 4px solid #fff;
    border-radius: 50% !important;
    margin-bottom: 35px !important;
}

.container-content-card .content-card {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    text-align: start;
}


.custom-carousel-winners ::v-deep .v-window-item {
    width: 400px;
    margin: auto;
}

.custom-carousel-winners ::v-deep .v-window-item .v-card {
    position: relative;
    height: 100% !important;
    border-radius: 25px !important;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: #ac141c !important;
    border: solid #fff;
    border-radius: 25px !important;
}

.custom-carousel-winners ::v-deep .v-window-item .v-card::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.custom-carousel-winners ::v-deep .v-window-item .v-card::after {
    content: '';
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.custom-btn {
    background: #ac141c !important;
    color: #fff !important;
}

.custom-image-roudend-winner:hover {
    cursor: pointer;
}

/* Contenedor que usa CSS Grid */
.personas-grid {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    /* 5 columnas de 20% de ancho cada una */
    grid-gap: 20px 10px;
    /* Espaciado entre los elementos */
    /* justify-content: center; Centra el grid */
    margin-top: 10px;
}

/* Asegura que las imágenes tengan el estilo deseado */
.custom-image-roudend-winner {
    border-radius: 50%;
    border: 2px solid #fc3c24;
}

.card-all-winners-ace-of-spades {
    position: relative;
    height: 550px !important;
    border-radius: 25px !important;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: #ac141c !important;
    border: solid #fff;
    border-radius: 25px !important;
}

.container-content-card {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-flow: column;
}

.container-content-card .v-image {
    border: 4px solid #fff;
    border-radius: 50% !important;
    margin-bottom: 35px !important;
}

.container-content-card .content-card {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    text-align: start;
}

.card-all-winners-ace-of-spades::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/spade-gold.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.card-all-winners-ace-of-spades::after {
    content: '';
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/spade-gold.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
</style>